import { useVista, Vista, ViewModel, core } from "@essenza/react";
import { Button, Table } from "antd";
import { useMemo } from "react";
import { Attachment } from "../component/attachment";

export function AttachmentVista() {
    const vm = useVista(AttachmentVVM);
    const col = useMemo(() => Cols(vm), [vm]);
    //onSuccess={(r, d) => vm.onsuccess(r, d)}
    return (
        <Vista>
            <div className="flex gap-2 my-2">
                <Attachment idfolder={vm.idfolder} idpatient={vm.idpatient}  />
                <Button className="bg-pri" onClick={() => vm.context.navigate(-1)}>INDIETRO</Button>
            </div>
            <Table dataSource={vm.source} columns={col} pagination={false} />
        </Vista>
    )
}

export function AttachmentVVM() {
    ViewModel.call(this);
    const navdata = this.context.navdata;
    this.source = navdata.source || [];
    this.idpatient = navdata.idpatient;
    this.idfolder = navdata.idfolder;
}

core.prototypeOf(ViewModel, AttachmentVVM, {
    onsuccess(result, data) {
        this.source.push({ title: data.title, id: result.data, date: new Date(), ext: ".pdf" });
        this.source = [...this.source];
        this.update();
    }
});

function Cols(vm) {
    return [
        {
            title: "Data",
            dataIndex: "date",
            key: "id",
            width: 120,
        },
        {
            title: "Titolo",
            dataIndex: "title",
            key: "id",
            width: 'auto',
        },

        {
            title: "",
            key: "id",
            render: (text, record) => {
                return (
                    <a href={"/attachment/" + record.id + record.ext} target="_blank" rel="noreferrer">Visualizza</a>
                );
            },
            width: 60,
        },
    ];
}