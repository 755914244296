import { Button, Table } from "antd";
import { Widget, useWidget } from "@essenza/react";
import { useMemo } from "react";

export function PatientTableLite({ source }) {
    const vm = useWidget()
    const col = useMemo(()=>liteCols(vm),[vm])
    return <Widget>
        <Table dataSource={source} onRow={(record) => ({
          onClick: () => {
            vm.emit("SELECT", record);
            console.log("SELECT FROM ROW");
          }
        })} columns={col} pagination={false} />
    </Widget>
}

function liteCols(vm) {
    return [
        {
            title: "Codice",
            dataIndex: "id",
            key: "id",
            responsive: ['md'],
        },
        {
            title: "Nome",
            dataIndex: "tname",
            key: "id",
            width: 200,
        },
        {
            title: "Cognome",
            dataIndex: "tsurname",
            key: "id",
            width: 200,
        },
        {
            title: "Data Nascita",
            dataIndex: "dborn",
            key: "id",
            width: 200,
        },
        {
            title: "Codice Fiscale",
            dataIndex: "tfiscalcode",
            key: "id",
            width: 200,
            responsive: ['md'],
        },
        {
            title: "Telefono",
            dataIndex: "tphonea",
            key: "id",
            width: 200,
            responsive: ['md'],
        },
        {
            title: "Email",
            dataIndex: "temail",
            key: "id",
            width: 200,
            responsive: ['lg'],
        },
        {
            title: "Ultima visita",
            dataIndex: "dlastvisit",
            key: "id",
            width: 200,
            responsive: ['lg'],
        },
        {
            title: " ",
            key: "id",
            render: (text, record) => {
                return (
                    <div>
                        <button className="btn-dark bg-sec" onClick={(e) => { vm.emit("SELECT", record); e.stopPropagation(); }}>SELEZIONA</button>
                    </div>
                );
            },
            responsive: ['md'],
        },
    ];
}