import './print.css';
import header from "../assets/img/ft_intestazione.gif";
import fheader from "../assets/img/ft_intestazione_fisio.gif";
import paziente from "../assets/img/dati_paziente.gif";
import esamigif from "../assets/img/esami_effettuati.gif";
import { Col, Row, Space } from "antd";
import React from "react";
import { Format } from "../core/dataFormatter";
import { $Type } from "@essenza/react";

export const PrintInvoices = React.forwardRef(({ data, type, total }, ref) => {
    const Euro = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
    });

    return (
        <div ref={ref} className="invoice">
            <img src={type === 1 ? fheader : header} alt="intestazione Fattura" />
            <div className="flex font-bold">
                <div className="basis-1/6">Data</div>
                <div className="basis-1/12">Numero</div>
                <div className="basis-1/12">Anno</div>
                <div className="basis-1/3">Nominativo</div>
                <div className="basis-1/6">Tipo</div>
                <div className="basis-1/6">Importo</div>
            </div>
            {
                data && data.map(inv =>
                    <div className="flex border-t border-black">
                        <div className="basis-1/6">{inv.date}</div>
                        <div className="basis-1/12">{inv.invid}</div>
                        <div className="basis-1/12">{inv.year}</div>
                        <div className="basis-1/3">{inv.fullname}</div>
                        <div className="basis-1/6">{inv.itype === "F" ? "FATTURA" : "NOTA DI CREDITO"}</div>
                        <div className="basis-1/6">{Euro.format((inv.taxable || 0) + (inv.tax || 0))}</div>
                    </div>
                )
            }
            <div className="p-4 border-black border font-bold">
                Totale {Euro.format(total)}
            </div>

        </div>
    )
});