import { Button, Input, message, notification, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { core } from "@essenza/react";
import { useMemo, useState } from "react";

export function Attachment({ idpatient, idfolder, onSuccess }) {
    const ui = useMemo(() => new UI(), []);
    const [title, setTitle] = useState(null);

    const onsuccess = (r,d)=>{
        ui.onSuccess(r);
        onSuccess && onSuccess(r, d);
    }
    //beforeUpload={beforeUpload} 
    return (<div className="flex gap-2">
        <Input placeholder="titolo documento" onChange={e => setTitle(e.target.value)}></Input>
        <Upload name="cv" onChange={f => ui.onchange(f)} onSuccess={onsuccess} customRequest={o => ui.upload(o)} data={{ title, idpatient, idfolder, url: 'api/udoc' }}>
            <Button className="primaryButton w100" icon={<UploadOutlined />} block>
                Carica Documento
            </Button>
        </Upload>
    </div>
    )
}

export function UI() {
    this.title = "Allegato";
}

/*UI.prototype = Object.create(UI.prototype, {
    constructor: {
        value: UI,
        enumerable: false,
        writable: true,
        configurable: true,
    },
    $$type: { value: target },
});*/
UI.prototype = {
    onchange: ({ fileList: newFileList }) => {
        if (newFileList) {
            newFileList[newFileList.length - 1].status = "done";
        }
    },

    beforeUpload: (file) => {
        if (file.type === 'application/pdf')
            return true;
        else {
            notification.info({ message: "Formato file non supporto. Formato supportato pdf" })
            return Upload.LIST_IGNORE;
        }
    },

    onSuccess: (r) => {
        message.success("File caricato con successo!");
    },

    upload: function (options) {
        const { onSuccess, onError, file, onProgress, data, setProgress } = options;
        console.log("START UPLOAD", options);
        console.log("START UPLOAD 2", data);

        const option = data.option || {};
        const formData = new FormData();
        /*files.forEach((file) => {
          formData.append("files[]", file);
        });*/
        formData.append(option.name || "formFile", file);
        for (const key in data) {
            if (key !== 'option' && Object.hasOwnProperty.call(data, key)) {
                console.log("UPLOAD DATA", key, data[key]);
                formData.append(key, data[key]);
            }
        }

        const config = {
            //headers: { "Content-type": "multipart/form-data" },
            excludeParams: true,
            hasbody: true
        };

        if (setProgress) {
            config.onUploadProgress = (event) => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            };
        }
        console.log("UPLOAD URL", options.data.url || this.url);
        this.api.call(options.data.url || this.url, formData, config).then((result) => {
            console.log("UPLOAD SUCCESS", result, onSuccess);
            if (option.onSuccess) option.onSuccess(result, data, file);
            onSuccess(result, data);
        }, onError);
    }
}

core.inject(UI, "IApi"); //,IContext

