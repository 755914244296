import { Avatar, Badge, Button, Checkbox, Col, Collapse, Input, InputNumber, message, notification, Row, Space, Table, Tooltip, Upload } from "antd";
import { UploadOutlined, FileTextFilled } from '@ant-design/icons';
import { useWidget, Widget, core, ViewModel, PopOverButton, PopupButtonVM } from "@essenza/react";
import { Format } from "../../core/dataFormatter";
import { todecimal } from "../../core/utils";
import { ExamSelection, ExamSelectionVM } from "../exam/selection";
import { MdOutlineDeleteSweep } from "react-icons/md";

const { Panel } = Collapse;

const options = { 'weekday': 'long', 'month': 'long', 'day': '2-digit', 'hour': 'numeric', 'minute': 'numeric' };
const itype = ['ESAME', 'II LIV.', 'PRESCR.'];

export function VisitList({ source, title, exams }) {
    const vm = useWidget(VisitListVM);
    if (!source) return null;

    return (<Widget>
        <h2 className="text-center">{title}</h2>
        <Collapse collapsible="icon" defaultActiveKey={["0"]} className="mt-8 text-left" >
            {
                source.map((visit, i) => {
                    if (!visit) return null;
                    const state = vm.getState(visit);
                    visit.invoicing = state.invoicing;

                    return <Panel className=" fw-7" key={i} collapsible="icon"

                        header={<b>{new Date(visit.dstart).toLocaleString('it-IT', options) + ' - ' + Format.moment(visit.dend, 'HH:mm')}</b>}

                        extra={
                            visit.state === 10 ? <h1 className="font-bold">ANNULLATA</h1> 
                            : (state.invoiced
                                ? <a className="" onClick={() => vm.emit("SHOW_INV", visit.invid)}>Visualizza Fattura</a>
                                : <>
                                    <PopOverButton title="Aggiungi Esami" hide={vm.hide} content={<ExamSelection source={exams} visit={visit} />} style={{ marginLeft: '24px' }} className="pl-2 btn-dark bg-pri" />
                                    {!state.booking && <span className="ml-3"><Checkbox  className="ml-md" onChange={e => visit.invoicing = e.target.checked}>Fattura</Checkbox></span>}
                                </>)
                        }>
 
                        {//visit.exams --- defaultChecked={!visit.state}
                            visit.exams && visit.exams.map((exam) => {
                                if (!exam) return null;
                                
                                if (isNaN(exam.price) || exam.price === null)
                                    exam.price = todecimal(exam.price);

                                return <div className="flex items-center flex-wrap mb-3 gap-3">
                                    <div className="flex-auto min-w-72">{visit.invid > 0 ? exam.title : <Input onBlur={e => exam.$title = e.target.value} defaultValue={exam.title}></Input>}</div>
                                    <div className="px-4 grow sm:flex-none">{itype[exam.itype || 0]}</div>
                                    <div flex="140px">
                                        {state.invoiced ? '' : <InputNumber onBlur={e => exam.$price = Number(e.target.value)} defaultValue={exam.price} prefix="€" style={{ width: '100%' }} />}
                                    </div>
                                    {/* {state.complete && <>
                                        <div className="flex-none">
                                            <Upload>
                                                <Button icon={<UploadOutlined />}>Carica Documento</Button>
                                            </Upload>
                                        </div>
                                        <div className="flex-none">
                                            <Badge count={exam.attached}>
                                                <Avatar shape="square" icon={<FileTextFilled />} />
                                            </Badge>
                                        </div>
                                    </>} */}
                                    {!state.invoiced && <Tooltip title="Elimina" placement="bottom"><MdOutlineDeleteSweep className="text-3xl cursor-pointer" onClick={(e) => { vm.emit("DELETE", exam); }} /></Tooltip>}
                                </div>
                            })
                        }
                    </Panel>
                })
            }
        </Collapse>
    </Widget>)
}

export function VisitListVM() {
    ViewModel.call(this);
    this.bind(ExamSelectionVM).as("exam").listen("SELECTION", this);
    this.bind(PopupButtonVM).as("popup");
}

core.prototypeOf(ViewModel, VisitListVM, {
    getState(visit) {
        return { booking: visit.state === undefined, invoicing: false, invoiced: visit.state === 1, complete: visit.state > 0 }
    },
    intent: {
        SELECTION() {
            this.popup.setVisible(false);
            this.update();
        },

        SHOW_INV({ data }) {
            this.context.navigate("invoice", data);
        },

        DELETE({ data }) {
            data && data.delete();
        }
    }
});