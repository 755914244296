import { useWidget, Widget, core, ViewModel, useModel, InputFilter } from "@essenza/react";
import { Button, Col, Divider, Row } from "antd";
import { PatientTableLite } from "../patient/table";
import { PatientModel } from "../../data/patientmodel";
import { AgendaEvent } from "../agenda/event";

export const BookVisita = ({ visit }) => {
    const vm  = useWidget(BookVisitaVM);
    const [patient, data] = useModel(PatientModel);

    console.log("BOOKVISITA", BookVisita.name);

    return (<Widget>
            {visit && <AgendaEvent source={visit} />}
            <Divider />
            <div className="flex py-2 gap-2">
                <div className="grow">
                    <InputFilter placeholder="Cognome nome" waiting={800} digits={5} onDigits={(v) => vm.emit("SEARCH", v)} field="fullname" model={patient} source={data} />
                </div>
                <div className="flex-none">
                    <button className="btn-dark bg-pri" onClick={() => { vm.emit("ADD_PATIENT", patient.newInstance()); }}>Nuovo Paziente</button>
                </div>
            </div>
        <PatientTableLite source={data} />
    </Widget>)
}

export function BookVisitaVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, BookVisitaVM, {
    intent:{
        SEARCH({ data }){
            this.$patient.search(data);
        },

        ADD_PATIENT: ({ value }) => {
            //c.source(PatientModel).item = value;
        }
    }
});