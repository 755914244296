import './App.css';
import { AppRoot, useApp, useBreakPoint } from "@essenza/react";
import { Route, Routes } from 'react-router-dom';
import { ConfigureApp } from './config';
import { MainLayout } from './layout/MainLayout';
import { MobileLayout } from './layout/MobileLayout';
import { WelcomeVista } from './vista/welcome';
import { HomeVista } from './vista/home';
import { Mobile } from './widget/mobile';
import { AgendaVista } from './vista/agenda';
import { loadCldr } from '@syncfusion/ej2-base';
import { BookVista } from './vista/book';

import { LoginVista } from './vista/profile/login';
import { ProfileVista } from './vista/profile/profile';
import { InviteVista } from './vista/profile/invitein';
import { UserVista } from './vista/profile/detail';
import { UserAdminVista } from './vista/profile/admin';
import { SportModel } from './data/sport';
import { FolderVista } from './vista/folder';
import { InvoiceVista } from './vista/invoice';
import { InvoicesVista } from './vista/invoices';
import { PatientVista } from './vista/patient';
import { FoldersVista } from './vista/folders';
import { AttachmentVista } from './vista/attachment';

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/it/ca-gregorian.json'),
  require('cldr-data/main/it/numbers.json'),
  require('cldr-data/main/it/timeZoneNames.json')
);

const init = app => {
  app.observe("BUILD").make(() => console.log("APP BUILD OBSERVED")).prepend();
  app.observe("BUILD").make(() => console.log("APP BUILT OBSERVED"));
  app.observe("LOADED").make(() => console.log("APP LOADED OBSERVED"));
  app.observe("READY").make(() => console.log("APP READY OBSERVED")).once();
  
  app.observe("LOGGED").make(() => {
    console.log("LOGGED", app);
    app.navigate("agenda");
  });
  
  app.observe("AUTH").make(() => app.navigate("login"));

  app.setSource("doctors", [{ id: 1, label: "Dott. Fabrizio Galeotti" }, { id: 2, label: "Dott. Giammarco Triarico" }]);

  ConfigureApp(app);

  app.block.wait(new SportModel().createSource("sports"));
}

function App() {
  const app = useApp(init);

  return (
    <div className="App">
      <AppRoot >
        <Routes>
          <Route path="/" element={<MainLayout /> } >
            <Route path="home" element={<AgendaVista />} />
            <Route path="agenda" element={<AgendaVista />} />
            <Route path="patient" element={<PatientVista />} />
            <Route path="folders" element={<FoldersVista />} />
            <Route path="documentms" element={<InvoicesVista igroup={0} />} />
            <Route path="documentfis" element={<InvoicesVista igroup={1} />} />
            <Route path="book" element={<BookVista />} />
            <Route path="folder" element={<FolderVista />} />
            <Route path="settings" element={<UserAdminVista />} />
            <Route path="user-detail" element={<UserVista />} />
            <Route path="profile" element={<ProfileVista />} />
            <Route path="invite" element={<InviteVista />} />
            <Route path="invoice" element={<InvoiceVista />} />
            <Route path="attachment" element={<AttachmentVista />} />
            <Route path="*" element={<AgendaVista />} />
          </Route>
          <Route index element={<WelcomeVista />}  />
          <Route path="login" element={<LoginVista />} />
        </Routes>
      </AppRoot>
    </div>
  );
}

export default App;