export const DateEnum = { day: 'Day', week: 'WorkWeek', month: 'Month' }

export function DateInterval(format, date) {
  format = format || DateEnum.day;
  date = date || new Date();
  console.log("DateInterval", format, date);
  date.setHours(0, 0, 0, 0);
  let te;
  if (format === DateEnum.day) {
    te = new Date(date);
    te.setDate(date.getDate() + 1)
    return { ts: date.toISOString().substring(0, 19), te: te.toISOString().substring(0, 19), current: date  };
  }
  else if (format === DateEnum.week) {
    let day = date.getDay() - 1;
    if (day === -1)
      day = 6;

    date.setDate(date.getDate() - day);
    te = new Date(date);
    te.setDate(date.getDate() + 6);
    return { ts: date.toISOString().substring(0, 19), te: te.toISOString().substring(0, 19), current: date  };
  }
  else if (format === DateEnum.month) {
    date.setDate(1);
    let day = date.getDay() - 1;
    if (day === -1)
      day = 6;

    date.setDate(date.getDate() - day);
    te = new Date(date);
    te.setDate(date.getDate() + 35);
    return { ts: date.toISOString().substring(0, 19), te: te.toISOString().substring(0, 19), current: date };
  }
}

export function toTime(d) {
     const h = (d.getHours()<10?'0':'') + d.getHours();
     const m = (d.getMinutes()<10?'0':'') + d.getMinutes();
    return h + ':' + m;
}

export function sleep(timeout) {
  return new Promise((resolve) => setTimeout(resolve, timeout));
}

export const Euro = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});

var STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;
var ARGUMENT_NAMES = /([^\s,]+)/g;
function getParamNames(func) {
  var fnStr = func.toString().replace(STRIP_COMMENTS, '');
  var result = fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(ARGUMENT_NAMES);
  if (result === null)
    result = [];
  return result;
}

export function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function isString(s) {
  return typeof s === 'string';
}

export function todecimal(value) {
  console.log("TO DECIMAL", value );
  if (!value) return 0;
  var result = 0;
  if (value.indexOf("€") === -1)
    result = Number(value.replace(/\,/g, "."));
  else if (value[0] === "€")
    result = Number(value.substr(2, value.length - 2).replace(/\,/g, "."));
  else
    result = Number(value.substr(0, value.length - 2).replace(/\,/g, "."));
  return result;
}

export function ArrayMoveElementAt(arr, fromIndex, toIndex) {
  const element = arr.splice(fromIndex, 1)[0];
  console.log(element); 
  arr.splice(toIndex, 0, element);
}

export function ArrayOrderElementAt(arr, fromIndex, toIndex, field) {
  field = field || "iorder";

  ArrayMoveElementAt(arr, fromIndex, toIndex);
  
  let s, e;
  if(fromIndex < toIndex){
    s= fromIndex;
    e = toIndex+1;
  }
  else{
    s= toIndex;
    e = fromIndex+1;
  }
  for (let k = s; k < e; k++) {
    arr[k][field] = k+1;
  }
}

const INTERVAL = { LAST_MONTH: 'M' };

export function DateSearch(start, end) {
  this.start = start || new Date();
  this.start.setHours(0, 0, 0, 0);
  this.end = end || this.start;
  this.isMonthly = false;
}

DateSearch.prototype = {
  localMonth(index) {
    if (index instanceof Date) index = index.getMonth();
    if (index < 0) index = 12 + index;
    return ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"][index];
  },

  format: function (val) {
    if (val === INTERVAL.LAST_MONTH) {
      this.end = new Date();
      this.end.setDate(0);
      this.start = new Date(this.end.getTime());
      this.start.setDate(1);
    }
  },

  Month: function (m) {
    if(m===undefined)
      this.end = new Date();
    else if(m instanceof Date )
      this.end = m;
    else{
      this.end = new Date();
      this.end.setMonth(m);
    }

    this.end.setDate(32);
    this.end.setDate(0);

    this.start = new Date(this.end.getTime());
    this.start.setDate(1);
    this.isMonthly = true;
  },

  LastDays: function (days) {
    this.end = new Date();
    if(!days){
      days = this.end.getDate()-1;
    }
    //this.end.setHours(this.end.getHours() - (this.end.getTimezoneOffset()/60))
    this.start = new Date(this.end.getTime() - (days * 24 * 60 * 60 * 1000));
    //this.start.setDate(this.end.getDate() - days);
    this.isMonthly = false;
  },

  LastMonth: function (last) {
    this.end = new Date();
    //this.end.setHours(this.end.getHours() - (this.end.getTimezoneOffset()/60))
    last = last || 1;
    if (last < 1) last = 1;

    for (let k = 0; k < last; k++) {
      this.end.setDate(0);
    }
    
    this.start = new Date(this.end.getTime());
    this.start.setDate(1);
    this.isMonthly = true;
  },

  clone: function () {
    return new DateSearch(this.start, this.end);
  },

  monthlyBeforeOf: function(date){
    if(!date) return false;
    if(typeof date === 'string') date = new Date(date);
    return date && this.isMonthly && this.end.getTime() < date.getTime();
  },

  toISO: function () {
    
    return { start: this.start.toISOString().substring(0, 19), end: this.end.toISOString().substring(0, 19) };
  },
  toISODate: function () {
    let start = new Date(this.start.getTime() - (this.start.getTimezoneOffset() * 60 * 1000));
    let end = new Date(this.end.getTime() - (this.end.getTimezoneOffset() * 60 * 1000));
    return { start: start.toISOString().substring(0, 10), end: end.toISOString().substring(0, 10) };
  },
  
  toIntervalString(){
    let sm = this.start.getMonth();
    let em = this.end.getMonth();
    return sm === em 
      ? this.start.getDate() + "-" + this.end.getDate() + " " + this.localMonth(sm) + " " + this.end.getFullYear()
      : this.start.getDate() + this.localMonth(sm) + " - " + this.end.getDate() + " " + this.localMonth(em) + " " + this.end.getFullYear();
  },

  toMonthlyLabel(){
    let sm = this.start.getMonth();
    return this.isMonthly ? (this.localMonth(sm) + " " + this.end.getFullYear()) : this.toIntervalString();
  },

  get isValid(){
    return this.start && this.end && this.end.getTime() > this.start.getTime()
  }

}