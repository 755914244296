
import { useVista, useModel, Vista, ViewModel, core, Printer } from "@essenza/react";
import { useMemo } from "react";
import { Button, DatePicker, Input, Radio, Table } from "antd";
import { InvoiceModel } from "../data/invoice";
import dayjs from 'dayjs';
import { DateSearch, Euro } from "../core/utils";
import { BsVolumeMute } from "react-icons/bs";
import { PrintInvoices } from "../print/printinvoices";

export function InvoicesVista({ igroup }) {
    const vm = useVista(InvoicesVVM);

    const [model, data] = useModel(InvoiceModel);

    vm.$$init(vm => {
        const ndata = vm.context.navdata;
        if (ndata) {
            model.of(ndata);
        }
    });

    const col = useMemo(() => Cols(vm), [vm]);

    useMemo(() => {
        vm.igroup = igroup;
        model.today(igroup);
    }, [igroup])

    const total = useMemo(() => {
        let sum = 0;
        if (data) {
            data.forEach(el => {
                sum += el.taxable + el.tax;
            });
        }
        return sum;
    }, [data]);
    //if (!data) return <h1 className="text-center text-lg mt-3">Nessun documento da visualizzare</h1>

    return (
        <Vista>
            <div className="flex gap-2 py-3 items-center">
                {/* {igroup === 0 ? <h2 className="whitespace-nowrap font-bold">MEDICINA SPORTIVA</h2> : <h2 className="font-bold">FISIOTERAPIA</h2> } */}
                <Button className='btn-pri mr-sm' onClick={() => model.today(vm.igroup)}>FATTURE ODIERNE</Button>
                <DatePicker onChange={date => vm.onInterval(date.toDate(), true)} className="h-8 bg-white min-w-36" format="DD-MM-YYYY" defaultValue={dayjs(vm.interval.start)} />
                <DatePicker onChange={date => vm.onInterval(date.toDate())} className="h-8 bg-white min-w-36" format="DD-MM-YYYY" defaultValue={dayjs(vm.interval.end)} />
                <Button className='btn-pri mr-sm' onClick={() => vm.fromto()}>CERCA PER DATA</Button>
                <Input placeholder="cognome nome" onChange={e => vm.text = e.target.value} />
                <Button onClick={() => vm.search()} className='btn-pri mr-sm'>CRECA PER PAZIENTE</Button>
                <Button onClick={() => vm.print({data, total})} className='btn-pri mr-sm'>STAMPA</Button>
            </div>
            <Table dataSource={data} columns={col} pagination={false} />
            <div className="p-4 bg-amber-500 border-l font-bold">
                Totale {Euro.format(total)}
            </div>
        </Vista>
    )
}

export function InvoicesVVM() {
    ViewModel.call(this);
    this.igroup = 0;
    this.text = null;
    this.interval = new DateSearch();
    this.interval.LastDays();
}

core.prototypeOf(ViewModel, InvoicesVVM, {

    onMonth(month) {
        this.interval = new DateSearch();
        this.interval.Month(month.toDate());
        this.unselected = true;
        this.update();
    },

    onInterval(date, start) {
        this.interval = this.interval.clone();

        if (start)
            this.interval.start = date;
        else
            this.interval.end = date;
    },

    fromto() {
        this.interval.isValid && this.$invoice.fromto(this.interval, this.igroup);
    },

    search() {
        this.text && this.$invoice.search(this.text);
    },

    print(data) {
        data.type = this.igroup;
        const printer = {};
        this.context.openModal({
            kind: "confirm",
            content: <div><Printer document={PrintInvoices} data={data} printer={printer} /></div>,
            title: "LISTA FATTURA",
            okText: "STAMPA",
            cancelText: "ANNULLA",
            centered: true,
            width: 850,
            onOk: () => printer.print(),
        })
    },

    intent: {
        DETAIL({ data }) {
            this.context.navigate("invoice", data.id);
        }
    }
});

function Cols(vm) {
    return [
        {
            title: "Data",
            dataIndex: "date",
            key: "id",
            width: 120,
        },
        {
            title: "Numero",
            dataIndex: "invid",
            key: "id",
            width: 70,
        },
        {
            title: "Anno",
            dataIndex: "year",
            key: "id",
            width: 70,
        },
        {
            title: "Nominativo",
            dataIndex: "fullname",
            key: "id",
            width: 220,
        },
        {
            title: "Tipo",
            render: (text, record) => record.itype === "F" ? "FATTURA" : "NOTA DI CREDITO",
            key: "fullname",
            width: 220,
        },
        {
            title: "Importo",
            key: "id",
            render: (text, record) => Euro.format((record.taxable || 0) + (record.tax || 0)),
            width: 'auto',
        },
        {
            title: "",
            key: "id",
            render: (text, record) => {
                return (
                    <Button onClick={() => { vm.emit("DETAIL", record); }} >VISUALIZZA</Button>
                );
            },
            width: 60,
        },
    ];
}