import { Button, DatePicker, Input, message, Select } from "antd";
import { SelectFilter, ComuneModel, useModel, FormItem, ViewModel, core, useWidget, Widget, PersonModel } from "@essenza/react";

export function CodiceFiscale({ person, form }) {
    const vm = useWidget(FiscalCodeVM);
    const data = useModel(ComuneModel, [{value: person?.blocality, label: person?.blocality_label}])[1];
    vm.person = person;
    vm.form = form;

    return <Widget>
        <div className="flex gap-2 flex-wrap w-full">
            <div className="flex-auto sm:min-w-80">
                <FormItem name="tsurname" label="Cognome">
                    <Input placeholder="Cognome" />
                </FormItem>
            </div>

            <div className="flex-auto min-w-72 sm:min-w-80">
                <FormItem name="tname" label="Nome">
                    <Input placeholder="Nome" />
                </FormItem>
            </div>

            <div className="flex-none">
                <FormItem name="tgender" label="Sesso">
                    <Select defaultValue="M" >
                        <Select.Option value="M">M</Select.Option>
                        <Select.Option value="F">F</Select.Option>
                    </Select>
                </FormItem>
            </div>

            <div className="flex-none">
                <FormItem name="dborn" label="Data Nascita">
                    <DatePicker style={{ minWidth: '120px' }} format={['DD/MM/YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD']} />
                </FormItem>
            </div>

            <div className="flex-auto">
                <FormItem name="blocality" label="Comune Di Nascita">
                    <SelectFilter options={data} onSelect={(v, o) => { vm.emit("LOCALITY", o) }} onDigits={(v) => vm.emit("SEARCH", v)} placeholder="Comune Di Nascita" style={{ width: '100%' }} />
                </FormItem>
            </div>

            <div className="flex-none">
                <FormItem name="bcountry" label="Stato">
                    <Input disabled={true} placeholder="Stato" style={{ width: '70px' }} />
                </FormItem>
            </div>

            <div className="flex items-end gap-2">
                <FormItem name="tfiscalcode" label="Codice Fiscale">
                    <Input placeholder="Codice Fiscale" style={{ width: '180px' }} />
                </FormItem>
                <Button onClick={()=>vm.emit("CALCULATE")}>CALCOLA</Button>
            </div>
        </div>
    </Widget>
}

export function FiscalCodeVM() {
    ViewModel.call(this);
    /*this.oninit = vm => {
        vm.form.watch("tsurname,tname,tgender,dborn,blocality", true).make(({ data }) => {
            const v = data.values;
            console.log("CF VALUES", this.form.target.getFieldsValue(true), v);
            if(v.tfiscalcode || v.tfiscalcode === '') return;
            const person = new PersonModel();
            person.getFiscalCode({ tname: v.tname, tsurname: v.tsurname, dborn: v.dborn.format().split('T')[0], tgender: v.tgender, istat: v.blocality }).then(result => {
                //vm.form.target.setFieldValue("tfiscalcode", result.data);
                this.form.target.setFields([{touched: true, name: "tfiscalcode", value: result.data}]);
                //this.form.target.setFields([{touched: true, name: "tfiscalcode", value: result.data}]);
            })
        })
    }*/
    //observe data chenged (su form o su data?) e se tutti con valore => calcolo cf
}

core.prototypeOf(ViewModel, FiscalCodeVM, {
    intent: {
        SEARCH({ data }) {
            this.$comune.search(data);
        },

        LOCALITY: function ({ data }) {
            console.log("LOCALITY", data);
            //this.person.country = data.country;
            //this.form.target.setFieldValue("bcountry", data.country);
            this.form.data.blocality_label = data.label;
            this.form.target.setFields([{touched: true, name: "bcountry", value: data.country}]);
            this.update();
        },

        CALCULATE: function ({ data }) {
            const person = new PersonModel();
            const v = this.form.target.getFieldsValue(true);
            if(v.dborn && v.tgender && v.blocality && v.tname && v.tsurname ){
                person.getFiscalCode({ tname: v.tname, tsurname: v.tsurname, dborn: v.dborn.format().split('T')[0], tgender: v.tgender, istat: v.blocality }).then(result => {
                    this.form.target.setFields([{touched: true, name: "tfiscalcode", value: result.data}]);
                })
            }
            else{
                message.info("Dati mancanti per calcolare il codice fiscale");
            }
        }
    }
});

export function Address({ children, person, form }) {
    const vm = useWidget(AddressVM);
    const data = useModel(ComuneModel, [{value: person?.locality, label: person?.locality_label}])[1];
    vm.person = person;
    vm.form = form;

    console.log("ADDRESS", data);

    const content = <Widget>

        <FormItem className="flex-auto sm:min-w-80" name="street" label="Indirizzo">
            <Input placeholder="Indirizzo Residenza" />
        </FormItem>
        <FormItem className="flex-auto min-w-72 sm:min-w-80" name="locality" label="Comune Residenza">
            <SelectFilter options={data} onSelect={(v, o) => { vm.emit("LOCALITY", o) }} onDigits={(v) => vm.emit("SEARCH", v)} placeholder="Comune Residenza" />
        </FormItem>
        <FormItem name="city" label="Provincia">
            <Input disabled={true} placeholder="Provicia" style={{ width: '80px' }} />
        </FormItem>
        <FormItem name="code" label="CAP">
            <Input placeholder="CAP" style={{ width: '80px' }} />
        </FormItem>
        <FormItem name="country" label="Stato">
            <Input disabled={true} placeholder="Stato" style={{ width: '80px' }} />
        </FormItem>
    </Widget>;

    return (
        <>{content}{children}</>
    )
}

export function AddressVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, AddressVM, {
    intent: {
        SEARCH({ data }) {
            this.$comune.search(data);
        },

        LOCALITY: function ({ data }) {
            //this.form.target.setFieldValue("city", data.city);
            //this.form.target.setFieldValue("country", data.country);
            //this.form.target.setFieldValue("code", data.code);
            this.form.data.locality_label = data.label;
            this.form.target.setFields([{touched: true, name: "city", value: data.city}, 
            {touched: true, name: "country", value: data.country}, 
            {touched: true, name: "code", value: data.code}]);
            this.update();
        },
    }
});

export function Contact({ children }) {
    return <div className="flex flex-wrap gap-3 w-full">
        <FormItem className="flex-auto sm:min-w-80" name="tphonea" label="Telefono">
            <Input placeholder="Telefono" />
        </FormItem>
        <FormItem className="flex-auto sm:min-w-80" name="temail" label="Email">
            <Input placeholder="Email" />
        </FormItem>
        {children}
    </div>
}