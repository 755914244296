import React, { useEffect, useMemo } from "react";
import { useModel, useVista, Vista, core, ViewModel, } from "@essenza/react";
import { FolderModel } from "../data/folder";
import { Certificative, CertificativeVM } from "../widget/folder/certificative";
import { Fisio, FisioVM } from "../widget/folder/fisio";
import { Cardio } from "../widget/folder/cardio";
import { message } from "antd";
import { Invoice } from "../core/invoice";
import * as yup from 'yup';

export function FolderVista() {
	const vm = useVista(FolderVistaVVM);
	const [folder, data] = useModel(FolderModel);

	useEffect(() => {
		folder.detail(vm.context.navdata);
	}, [folder, vm.context.navdata])

	const state = useMemo(() => vm.format(data), [data, vm]);

	if (!data) return;

	const t = data.itype;

	let card = null;

	if (t === 0)
		card = <Certificative folder={data} state={state} validate />;
	else if (t === 1)
		card = <Fisio folder={data} state={state} validate />;
	else
		card = <Cardio folder={data} state={state} validate />;

	vm.setBind(t);

	return (<Vista>

		{card}
		<div className="flex justify-center gap-3 mt-2">
			{!state.booking && state.invoicing && <button className="btn-dark bg-sec" onClick={() => vm.emit("INVOICE", data)}>FATTURA</button>}
			<button className="btn-dark bg-sec" onClick={() => vm.emit("CONFIRM", data)}>CONFERMA</button>
			<button className="btn-dark bg-pri" onClick={() => vm.context.navigate("/agenda")}>ANNULLA</button>
			<button className="btn-dark bg-pri" onClick={() => vm.context.navigate("document", data.id)}>CONTABILITA</button>
			<button className="btn-dark bg-pri" onClick={() => vm.context.navigate("attachment", { idfolder: data.id, source: data.attachments, idpatient: data.patient.id })}>DOCUMENTI {data.attachments ? ('(' + data.attachments.length + ')') : ''}</button>
		</div>
	</Vista>
	)
}

function FolderVistaVVM() {
	ViewModel.call(this);
}

core.prototypeOf(ViewModel, FolderVistaVVM, {
	format(data) {
		return this.$folder.createState(data);
	},

	setBind(type) {
		this.bind([CertificativeVM, FisioVM][type]).as("folder");
	},

	intent: {
		async INVOICE({ data }) {
			const result = await this.folder.executeIntent("VALIDATE", yup.object({
				tname: yup.string().required("Nome è una informazione richiesta."),
				tsurname: yup.string().required("Cognome è una informazione richiesta."),
				tfiscalcode: yup.string().required("Codice Fisacle richiesto.").nullable(),
				street: yup.string().required("Indirizzo è una informazione richiesta."),
				locality: yup.string().required("Informazione richiesta.").nullable(),
			}));

			if (result.isValid) {
				console.log(data);
				const visits = data.visits;
				if (!visits) message.info("Nessuna visita da Fatturare");
				if (data.itype === 0) {
					let visit = visits.find(v => v.title === "Visita certificativa")
					if (visit) visit.title = "Visita certificativa " + (data.icertificate === 1 ? "AGONISTICA" : "NON AGONISTICA");
				}
				const patient = data.patient;
				const invoice = this.context.newInstance("invoice", new Invoice());//new Invoice();
				invoice.$idfolder = data.id;
				invoice.$date = new Date();
				invoice.$idpatient = patient.id;
				invoice.$fullname = patient.fullname;
				invoice.$fiscalcode = patient.tfiscalcode;
				invoice.$street = patient.street;
				invoice.$code = patient.code;
				invoice.$locality = patient.locality_label;
				invoice.$city = patient.city;
				invoice.$country = patient.country;
				invoice.$payment = 1; //Default: pos
				invoice.certificate = data.icertificate;
				invoice.$igroup = data.itype;
				invoice.$invid = 1;
				console.log("INV-PATIENT", invoice);
				let count = 0;
				let values = "";
				for (let k = 0; k < visits.length; k++) {
					const visit = visits[k];
					const exams = visit.exams;

					if (visit.invoicing && exams && exams.length > 0) {
						count++;
						values += visit.id + ",";

						for (let z = 0; z < exams.length; z++) {
							invoice.import(exams[z]);
						}
					}
				}

				if (count > 0) {
					const result = await this.folder.executeIntent("VALIDATE");
					console.log(result);
					result.isValid && data.save().then(() => this.context.navigate("invoice", { invoice, values }));
				}
				else
					message.info("Nessuna visita da Fatturare");
			}
			else {
				message.info("Dati mancanti per la fattuirazione");
			}
		},

		async CONFIRM({ data }) {
			console.log(data, this.$folder);

			const result = await this.folder.executeIntent("VALIDATE");
			console.log(result);
			result.isValid && data.save().then(() => this.context.navigate("agenda"));
			//;

			/*const folder = data.data;
			let node = data.node;
			const patient = folder.patient;
			const visit = folder.visits[0];

			//Papabile per un command ConfigInvoice
			const invoice = new Invoice(); // => LEGARE A NODE INVOICE DISENDANT DI FOLDER
			invoice.importItems(visit.exams); // => DEVE ASSOCIARE MUTATION A NODE INVITEM
			invoice.idfolder = folder.id;
			invoice.date = new Date();
			invoice.idpatient = patient.id;
			invoice.fullname = patient.fullname;
			invoice.fiscalcode = patient.tfiscalcode;
			invoice.street = patient.street;
			invoice.code = patient.code;
			invoice.locality = patient.locality_label;
			invoice.city = patient.city;
			invoice.country = patient.country;
			invoice.payment = value.payment;
			invoice.certificate = folder.icertificate;
			invoice.type = folder.itype;

			//invoice.procedure("invid", " COALESCE((SELECT Max(COALESCE(invid,0))+1 FROM invoice WHERE date_part('year',current_date)=year),1) ");

			console.log("SAVE ACCETTAZIONE", value, node, invoice);

			let result = await model.find(Patient, "patient-form").validate();
			//let form = c.form("patient-form");
			//let result = await form.validate();
			console.log("FOLDER FORM VALIDATION", result);

			if (!result.isValid) return;

			if (folder.itype === 0) {
				result = await model.find(CertificativeForm, "certificative-form").validate();
				console.log("FOLDER CERTIFICATIVE FORM VALIDATION", result);
			}

			//const node = source.node;

			const visits = node.getChild("visits"); // => COME SI PUò SEMPLIFICARE

			visits.mutate("state", 1, visit);

			//inode.returning = "invid";
			node.save().then((r) => {
				const response = r.data; //TODO: CLASSE RESPONSE CHE GESTISCE MUTATION DI RITORNO, CHE GESTISCE ANCHE SYNC CON ENTITY
				const inv = response.mutation.invoice[0];

				console.log("INVOICE SAVE", inv);

				invoice.invid = inv.data.invid;

				if (visit.invoicing) {
					const printer = {};
					inv.print
						? c.openPopup(<Printer document={PrintInvoice} data={{ invoice: invoice }} printer={printer} />, "STAMPA FATTURA",
							null, { onclose: () => c.navigate("/"), onconfirm: () => printer.print(), excludeCancel: true, confirm: "STAMPA" })
						: c.navigate('/agenda')
				}

			});*/

		},
	}
});
