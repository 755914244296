import { DataModel, core } from "@essenza/react";

export function InvoiceModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, InvoiceModel, {
    etype: "invoice",
    
    detail(id) {
        return this.ExecuteQuery("invoice_detail", { id: id });
    },

    remove(id, igroup) {
        return this.ExecuteQuery("invoice_remove", { id, igroup });
    },

    of(id) {
        return this.ExecuteQuery("invoice_of", { id: id });
    },

    today(igroup) {
        return this.ExecuteQuery("invoice_today", {igroup});
    },

    search(data) {
        return this.ExecuteQuery("invoice_search", {recipient: data});
    },

    fromto(interval, igroup) {
        const data = interval.toISODate();
        data.igroup = igroup;
        return this.ExecuteQuery("invoice_fromto", data);
    },
    
});